import React from 'react';
import * as env from './env'
let iosImg = '/images/apple-app-store.png';
let androidImg = '/images/google-play-store.png';

const ZippinApp = (props) => {
    if(env.getReceiptUIType()==="brightline") {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>To learn more about MRKT or submit feedback, click <a target="_blank" rel="noopener noreferrer" href={env.getReceiptUILink()}>here.</a></p>
			</div>
		)
	}else if(env.getReceiptUIType()==="lulu"){
		return (
			<div style={{ textAlign: 'center' }}>
				<h3>Get the <a target="_blank" style={{ color:"#0E86D4",textDecoration : "none" }} rel="noopener noreferrer" href="https://qrs.ly/vcag80j ">Lulu Shopping</a> App!</h3>
				<p>More Lulu information available <a target="_blank" style={{ color:"#0E86D4" }} rel="noopener noreferrer" href={env.getReceiptUILink()}>here.</a></p>
				<p>Send feedback to <a href="mailto:LE3080@qa.lulumea.com" style={{ color:"#0E86D4" }}>LE3080@qa.lulumea.com</a> </p>
			</div>
		)
	} else if(env.getReceiptUIType()==="almeera"){
		return (
			<div style={{ textAlign: 'center', paddingTop: 40 }}>
				<h3>Get the Al-Meera app!</h3>
				<div>
					<a href='https://apps.apple.com/in/app/meera-rewards/id1445590345' style={{ marginRight: 10 }}>
						<img src={iosImg} alt="Apple App Store" />
					</a>
					<a href='https://play.google.com/store/apps/details?id=googlecom.codelab.meera&hl=en_US&gl=US&pli=1'>
						<img src={androidImg} alt="Google Play Store" />
					</a>
					<p>More Al-Meera information available <a href="https://www.almeera.com.qa/index.php">here.</a></p>
				</div>
				<div style={{ textAlign: "center" }}>
					<span>
					Send feedback to <a href="mailto:customerservice@almeera.com.qa">customerservice@almeera.com.qa </a>
					</span>
					Tel: 40119111 – 40119112
				</div>
			</div>
		)
	} 
	else if (env.getReceiptUIType()=== "SSPDUBLIN") {
		return (
			<div style={{ textAlign: "center" }}>
				<p>It can take up to 30 minutes for your receipt to become available.</p>
				<span>
				Send feedback to <a href="mailto:Customer.care@ssp.uk.com">Customer.care@ssp.uk.com</a>
				</span>
			</div>
		)
	}
	else {
		return null
	}
}

export default ZippinApp