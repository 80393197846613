import React, { useState, useEffect } from "react"
import { DatePicker } from "@atlaskit/datetime-picker"
import InlineEdit from "@atlaskit/inline-edit"
import styled from "styled-components"
import { gridSize, fontSize } from "@atlaskit/theme"
import Textfield from "@atlaskit/textfield"
import ReceiptsList from "./ReceiptsList"
import ZippinApp from "./ZippinApp"
import { format, parse } from "date-fns"
import moment from "moment"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import * as env from "./env"
import ExpiryDateInput from "./ExpiryDateInput";
import './App.css';
import { Button, CircularProgress } from "@material-ui/core";
import { EURO } from "./common"
const styles = () => ({
  tooltip: {
    backgroundColor: "#fff",
    border: '1px solid grey',
    lineHeight: "1em",
    fontSize: "0.875rem",
  },
  fullOpacity: {
    opacity: 1,
  },
  arrow: {
    color: "#fff",
    "&::before": {
      backgroundColor: "#fff",
      border: "1px solid grey"
    }
  }
})

const ReadViewContainer = styled.div`
  display: flex;
  font-size: ${fontSize()}px;
  line-height: ${(gridSize() * 3) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 3) / fontSize()}em;
  padding: ${gridSize()}px ${gridSize() - 2}px;
  word-break: break-word;
  background-color: rgb(244, 245, 247);
`

function is_touch_device() {
  try {
    let prefixes = " -webkit- -moz- -o- -ms- ".split(" ")

    let mq = function (query) {
      return window.matchMedia(query).matches
    }

    if (
      "ontouchstart" in window ||
      (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)
    ) {
      return true
    }

    return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""))
  } catch (e) {
    console.error("(Touch detect failed)", e)
    return false
  }
}

const Receipts = (props) => {
  const { classes } = props
  const formattedDate = moment().format("MM/DD/YYYY");
  const [receipts, setReceipts] = useState([])
  const [dateTime, setDateTime] = useState(formattedDate);
  const [last4CC, setLast4CC] = useState("")
  const [cardExpirationMonth, setCardExpirationMonth] = useState("")
  const [cardExpirationYear, setCardExpirationYear] = useState("")
  const [defaultDateTime, setDefaultDateTime] = useState(formattedDate)
  let [open, setOpen] = useState(false)
  let [expiryTooltipOpen, setExpiryTooltipOpen] = useState(false)
  const [expiryDate, setExpiryDate] = useState({});
  const [showNoReceiptFound, setShowNoReceiptFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [last4Error, setLast4Error] = useState("");
  const [dateError, setDateError] = useState("");

  const handleExpiryDate = date => {
    console.log("handleExpiryDate = ", date)
    let expMonth = ''
    let expYear = ''
    if (date && date.month) {
      expMonth = date.month
    } 
    if (date && date.year) {
      expYear = date.year
    } 
    setCardExpirationMonth(expMonth)
    setCardExpirationYear(expYear)
    setExpiryDate(date);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleExpiryTooltipClose = () => {
    setExpiryTooltipOpen(false);
  };
  const handleExpiryTooltipOpen = () => {
    setExpiryTooltipOpen(true);
  };
  const handleDateChange = (date) => {
    setDateTime(date);
  };

  const fetchReceipts = async (last4CC1, dateTime1, cardExpirationMonth1, cardExpirationYear1) => {
    console.log("fetch receipts api dateTime: " + JSON.stringify(dateTime1))
    const date = moment(dateTime1).format("YYYY-MM-DD")
    const timeZone = moment(dateTime1).format("Z")
    const apiEndPoint = `${env.getTenantBackendBaseUrl()}/terminal_customer/receipts?date=${date}&last4=${last4CC1}&expmon=${cardExpirationMonth1}&expyear=${cardExpirationYear1}&timezone=${encodeURIComponent(timeZone)}`
    console.log("fetch receipts api endpoint: " + apiEndPoint)
    const response = await fetch(`${apiEndPoint}`, {
      method: "GET",
    })
    console.log("fetch receipts api resp: " + JSON.stringify(response))
    let json = await response.json()
    console.log("fetch receipts api json: " + JSON.stringify(json))
    console.log("fetch receipts api receipts: " + JSON.stringify(json.receipts))

    // calculate receipt values
    let receipts = json.receipts
    if (receipts.length === 0 ) {
      setShowNoReceiptFound(!(Array.isArray(receipts) && receipts.length > 0))
      setReceipts([]);
    }
    receipts.forEach((receipt) => {
      let subtotal = 0,
        totalDisc = 0,
        totalTaxes = 0,
        totalLoyalty = 0,
        loyaltyDetails = JSON.parse(receipt.loyalty_details),
        paymentPartnerDiscountDetails = JSON.parse(receipt.discount_details),
        discountDetails = null
      if (receipt && Array.isArray(receipt.items)) {
        receipt.items.forEach((item) => {
          subtotal += item.quantity * (item.base_price + item.crv)
          item.tax.forEach((tax, index) => {
            totalTaxes += item.tax[index]
          })
          item.discount.forEach((discount) => {
            totalDisc += discount
          })
          if (item.loyalty && item.loyalty.length > 0) {
            item.loyalty.forEach((loyalty) => {
              totalLoyalty += loyalty
            })
          }
        })
        if (loyaltyDetails || paymentPartnerDiscountDetails) {
          discountDetails = {}
          let loyaltyAndDiscountDetailsItems = []
            if (loyaltyDetails && Array.isArray(loyaltyDetails.items) && loyaltyDetails.items.length > 0) {
              let itemsInLoyaltyDetails = JSON.parse(JSON.stringify(loyaltyDetails.items))
              loyaltyAndDiscountDetailsItems = [...loyaltyAndDiscountDetailsItems, ...itemsInLoyaltyDetails]
            }
            if (paymentPartnerDiscountDetails && Array.isArray(paymentPartnerDiscountDetails.items) && paymentPartnerDiscountDetails.items.length > 0) {
              let itemsInPaymentPartnerDiscountDetailItems = JSON.parse(JSON.stringify(paymentPartnerDiscountDetails.items))
              itemsInPaymentPartnerDiscountDetailItems.forEach(itemsInPaymentPartnerDiscountDetail => {
                totalDisc -= itemsInPaymentPartnerDiscountDetail.discounts_applied
              })
              loyaltyAndDiscountDetailsItems = [...loyaltyAndDiscountDetailsItems, ...itemsInPaymentPartnerDiscountDetailItems]
            }
          if(loyaltyAndDiscountDetailsItems.length > 0){
            loyaltyAndDiscountDetailsItems.forEach(item => {
              if (item.discounts) {
                if (Array.isArray(item.discounts) && item.discounts.length > 0) {
                  item.discounts.forEach(di => {
                    if (Object.prototype.hasOwnProperty.call(discountDetails, di.description)) {
                      discountDetails[di.description] += di.amount
                    } else {
                      discountDetails[di.description] = di.amount
                    }
                  })
                }
              } else if (item.discounts_applied) {
                if (Object.prototype.hasOwnProperty.call(discountDetails, item.discount_description)) {
                  discountDetails[item.discount_description] += item.discounts_applied
                } else {
                  discountDetails[item.discount_description] = item.discounts_applied  
                }
              }
            })
          } else if (loyaltyDetails.discountAmount  && loyaltyDetails.discountAmount > 0) {
            let description = loyaltyDetails.description
            if(loyaltyDetails.description && Array.isArray(loyaltyDetails.description) && loyaltyDetails.description.length > 0) {
              description = loyaltyDetails.description.join(",")
            }
            discountDetails[description] = loyaltyDetails.discountAmount
          }
        }
      }
      let totalAmount = receipt.amount
      if(receipt.receiptFormat && receipt.receiptFormat === EURO) {
        subtotal = subtotal + totalTaxes
        totalAmount = totalAmount - totalTaxes
      }
      receipt.subtotal = subtotal
      receipt.totalTaxes = totalTaxes
      receipt.totalDisc = totalDisc
      receipt.totalLoyalty = totalLoyalty
      receipt.discountDetails = discountDetails
      receipt.totalPaid = totalAmount
    })
    console.log("receipts calced: ", receipts)
    setReceipts(receipts)
  }

  const getReceipts = async () => {
    setLast4Error("");
    setDateError("");
    let isVaidationError = false
    // Validate the last 4 digits
    if (last4CC.length !== 4 || !/^\d+$/.test(last4CC)) {
      setLast4Error("Please enter the last 4 digits of the card");
      isVaidationError = true
    }
    // Validate the selected date
    if (!dateTime) {
      setDateError("Please enter the date of your visit");
      isVaidationError = true
    }
    if(isVaidationError){
      return
    }
    setIsLoading(true);
    try {
     
      await fetchReceipts(last4CC, dateTime, cardExpirationMonth, cardExpirationYear);
    } 
    catch (error) {
      console.error("Error in getReceipts -", error);
    }finally {
      setIsLoading(false);
    }
  };
  

  let feedbackEmailSubject = "Feedback for card receipt"
  if (last4CC) {
    feedbackEmailSubject += " of last4 " + last4CC
  }
  if (dateTime instanceof Date && !isNaN(dateTime)) {
    const formattedDateTime = format(dateTime, "MM-dd-yyyy hh:mmaaaa OOOO");
    feedbackEmailSubject += " at " + formattedDateTime;
  }
  let emailString = `mailto:support@getzippin.com?subject=${feedbackEmailSubject}`
  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center"}} className={env.getReceiptUIType()==="brightline" ? "title-font" : "title"}>
        <div style={{flexGrow: 1, maxWidth: 250 }}>
          <div style={{ marginBottom: 8 }}>Date of your visit</div>
          <div>
          <DatePicker
              defaultValue={defaultDateTime}
              value={dateTime}
              onChange={handleDateChange}
              dateFormat="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
            />
            {dateError && <div className="error-message" style={{color:"red",paddingTop:10}}>{dateError}</div>}
          </div>
          <div style={{ display: "flex" , marginTop: 15}}>
            <div>Last 4 digits</div>
            <Tooltip
              enterDelay={200}
              leaveDelay={200}
              enterTouchDelay={0}
              leaveTouchDelay={15000}
              interactive
              arrow={true}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.fullOpacity,
                arrow: classes.arrow
              }}
              open={open}
              onOpen={handleTooltipOpen}
              onClose={handleTooltipClose}
              title={
                <div className={env.getReceiptUIType()==="brightline" && "brightline-font"}>
                  <div style={{ display:"flex", flexDirection:'row-reverse' }}>
                      <CloseIcon onClick={handleTooltipClose} style={{ cursor:'pointer', color:'grey', width: 20}} />
                   </div>
                  <p style={{color:'#000'}}> If you shopped using a physical card, enter the last 4 digits of the card. </p>
                  <p style={{color:'#000'}}> For <a rel='noopener noreferrer' href='https://support.apple.com/en-us/HT201469' target='_blank' style={{color:'#0000FF'}}>Apple Pay</a>, <a rel='noopener noreferrer' href='https://support.google.com/pay/answer/7643925' target='_blank' style={{color:'#0000FF'}}>Google Pay</a>, or other digital wallets, use the last 4 digits of the Device Account Number or Virtual Account Number.  This number is usually different from your physical card.</p>
                </div>
              }
            >
              <IconButton variant="contained" style={{ padding: 0, marginTop:-3, paddingLeft: 4 }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <div style = {{marginLeft: 15}}>Expiration date</div>
            <Tooltip
              enterDelay={200}
              leaveDelay={200}
              enterTouchDelay={0}
              leaveTouchDelay={15000}
              interactive
              arrow={true}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.fullOpacity,
                arrow: classes.arrow
              }}
              open={expiryTooltipOpen}
              onOpen={handleExpiryTooltipOpen}
              onClose={handleExpiryTooltipClose}
              title={
                  <div style={{ display:"flex", flexDirection:'row-reverse' }}>
                      <CloseIcon onClick={handleExpiryTooltipClose} style={{ cursor:'pointer', color:'grey', width: 20}} />
                  <p style={{color:'#000'}}>Expiration date is required for credit, debit, prepaid cards, and mobile wallets. </p>
                </div>
              }
            >
              <IconButton variant="contained" style={{ padding: 0, paddingLeft: 4, marginTop:-3 }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ display: "flex"}}>
            <InlineEdit
              hideActionButtons
              readViewFitContainerWidth
              defaultValue={last4CC}
              width={90}
              editView={(fieldProps) => (
                <>
                <Textfield
                  {...fieldProps}
                  type="tel"
                  pattern="[0-9]*"
                  maxLength={4}
                  onChange={(event) => setLast4CC(event.target.value)}
                  autoFocus
                  width={90}
                />
              </>
              )}
              readView={() => (
                <ReadViewContainer>{last4CC || "Enter Last 4"}</ReadViewContainer>
              )}
              onConfirm={(value) => console.log("confirm last4cc " + value)}
            />
            &nbsp;&nbsp;
            <div style = {{marginLeft: 20}}>
              <ExpiryDateInput
                label="Expiry Date"
                onDateChange={date => handleExpiryDate(date)}
                value={expiryDate}
                disabled={false}
                onBlur={date => handleExpiryDate(date)}
              />
            </div>
          </div>
          {last4Error && (
                  <div className="error-message" style={{paddingTop:10,color:"red"}}>{last4Error}</div>
                )}
          <div style = {{display:'flex', marginTop: 10, justifyContent:'center'}}>
          {isLoading ? (
              <CircularProgress size={24} /> // Display a loader when isLoading is true
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: "#9B28C4", color: "white" }}
                onClick={getReceipts}
                disabled={isLoading}
              >
                Find my receipt
              </Button>
            )}
        </div>
        </div>
      </div>
      <div>
      {Array.isArray(receipts) && receipts.length > 0 ? (
        <div>
          <ReceiptsList receipts={receipts} />
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {showNoReceiptFound
            ? "No receipts available. Check the last 4 digits, card expiration date, and date of your visit."
            : ""}
        </div>
      )}
      </div>
      <ZippinApp email={emailString}/>
    </>
  )
}

export default withStyles(styles)(Receipts)
