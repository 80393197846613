import React from "react"
import { convertBackToActualPrice, EURO } from "./common"

const Item = (props) => {
  const { item, isRefund , currencyConfig, receipt} = props
  let name = item.receiptName? item.receiptName : item.name
  let arabicName = null
  const regExp = /[\u0600-\u06FF]/g;
  if(regExp.test(item.name)){
    const splitName = item.name.split(")");
    const englishText = splitName[0].replace("(","");
    arabicName = splitName[1];
    name = englishText;
  }

  console.log("Item render", item)

  let itemTotalPrice = 0
  if (!isRefund && !isNaN(item.base_price) && !isNaN(item.quantity)) {
    itemTotalPrice = item.total_price.reduce((a, b) => a + b, 0)
    if (isNaN(item.crv)) {
      itemTotalPrice = item.quantity * item.base_price
    } else {
      itemTotalPrice = item.quantity * (item.crv + item.base_price)
    }
    if(receipt.receiptFormat && receipt.receiptFormat === EURO) {
      const itemTotalTax = item.tax.reduce((a, b) => a + b, 0)
      itemTotalPrice = itemTotalPrice + itemTotalTax
    }
  } else if (isRefund && item.amount) {
    itemTotalPrice = item.amount
  }
  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
      {!isRefund && (
        <span
          style={{
            alignSelf: "flex-start",
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {Array.isArray(item.images) && item.images[0] && item.images[0].url && (
            <img style={{ maxHeight: 50, maxWidth: 50 }} src={item.images[0].url} alt="item" />
          )}
        </span>
      )}
      {isRefund ? 
      ( 
       <span style={{ flexDirection: "column" ,flexGrow: 2 , display:"flex"}}>
          <span
            style={{
              alignItems : "flex-start",
              flexGrow: 2,
              paddingRight: 20,
              paddingLeft: 5,
            }}
          >
            {name}<br/>
            {item.quantity} item(s)
          </span>
          {arabicName &&
          <span
          style={{
            alignSelf: "flex-end",
            flexGrow: 2,
            paddingRight: 5,
            paddingLeft: 20,
          }}>{arabicName}
          </span> 
          }
        </span>
      ) : ( 
        <span style={{ flexDirection: "column" ,flexGrow: 2 , display:"flex"}}>
          <span
            style={{
              alignItems : "flex-start",
              flexGrow: 2,
              paddingRight: 20,
              paddingLeft: 5,
            }}
          >
            {name}<br/>
            {(receipt.receiptFormat && receipt.receiptFormat === EURO) ? (
              `${item.quantity} item(s)`
              ) : (
              `${item.quantity} @${convertBackToActualPrice(item.base_price + item.crv,currencyConfig,true)}`
            )}
          </span>
          {arabicName &&
          <span
          style={{
            alignSelf: "flex-end",
            flexGrow: 2,
            paddingRight: 5,
            paddingLeft: 20,
            }}
          >
            {arabicName}
          </span> 
          }
        </span>
      )}
      <span style={{ width: 100, textAlign:"right" }}>{convertBackToActualPrice(itemTotalPrice,currencyConfig,true).toString()}</span>   
    </div>
  )
}

export default Item
