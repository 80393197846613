import React from "react"
import { format, differenceInSeconds } from "date-fns"
import Item from "./Item"
import * as env from "./env"
import { convertBackToActualPrice, EURO } from "./common"

const getLoyaltyId = (memberId) => {
  let loyaltyId = null
  if (memberId && Array.isArray(memberId) && memberId.length > 0) {
    const result = memberId.map(ele => `*${ele.substr(ele.length - 4)}`)
    loyaltyId = result.join(",")
  } else {
    loyaltyId = `*${memberId.substr(memberId.length - 4)}`
  }
  return loyaltyId
}

const calcDurationTime = (receipt) => {
  let durationSecs = (receipt.entry_time && receipt.exit_time)  ? differenceInSeconds(new Date(receipt.exit_time), new Date(receipt.entry_time)) : 0
  if (durationSecs > 60) {
    return Math.round(durationSecs / 60) + "m and " + (durationSecs % 60) + "s"
  } else {
    return durationSecs + "s"
  }
}
const ReceiptsList = (props) => {
  const { receipts, showNoReceiptFound } = props
  console.log("ReceiptsList render: receipts ", receipts)
  console.log("ReceiptsList render: receipts ", JSON.stringify(receipts))
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
        <>
          <div style={{
            textAlign: "justify",
            maxWidth: 'auto',
            display: "flex",
            flexDirection: "column"
          }}>
            <p>Your receipt is retrieved using the last four digits of your credit card, which is not unique. You may see other receipts besides your own. Check your credit card statement to confirm the amount charged to your card.</p>
          </div>
          {receipts.map((receipt) => {
            let receiptText = []
            let receiptData = null
            if (receipt.receiptText) {
              let receiptValue = receipt.receiptText ? receipt.receiptText : null
              receiptText = receiptValue ? receiptValue.split("\n") : []
              if (receiptText.length === 1) {
                receiptText = receiptValue.split("\\n")
              }
            }
            if (receipt.receiptData) {
              receiptData = JSON.parse(receipt.receiptData)
            }
            let currencyConfig = { config: receipt.currencyConfig, symbol: receipt.currencySymbol }
            let transactionProblemSubject = "Problem with transaction : " + receipt.cart_id
            let transactionBody = "{Add your questions and requests here}\n\n"
            transactionBody += "#####################" + "\n" + "TRANSACTION DETAILS" + "\n" + "#####################" + "\n"
              + "Transaction # : " + receipt.cart_id + "\n" + "Store : " + receipt.name + "\n" + "Last 4 : " + receipt.last4 + "\n" + "Date : " + format(new Date(receipt.entry_time), "MM-dd-yyyy hh:mmaaaa OOOO");
            transactionBody = encodeURIComponent(transactionBody);
            let transactionEmail = `mailto:support@getzippin.com`
            if (env.getReceiptUIType() === "almeera") {
              transactionEmail += `,customerservice@almeera.com.qa`
            }
            if(env.getReceiptUIType() === "SSPDUBLIN") {
              transactionEmail = `mailto:customer.care@ssp.uk.com`
            }
            transactionEmail += `?subject=${transactionProblemSubject}&body=${transactionBody}`
            const paymentMethods = receipt.paymentMethods ? JSON.parse(receipt.paymentMethods) : []
            let refundTypesArray = receipt.refundMethods ? JSON.parse(receipt.refundMethods) : []
            let refundMethods = []
            refundTypesArray.reduce(function (res, value) {
              if (!res[value.refund_type]) {
                res[value.refund_type] = { refund_type: value.refund_type, name: value.name, amount: 0 };
                refundMethods.push(res[value.refund_type])
              }
              res[value.refund_type].amount += value.amount;
              return res;
            }, {});
            const loyalty_details = receipt.loyalty_details ? JSON.parse(receipt.loyalty_details) : {}
            return (
              <div
                key={receipt.cart_id}
                style={{ boxShadow: "0px 1px 10px rgba(0,0,0,0.08)", marginTop: 20, padding: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingBottom: 10,
                  }}
                >
                  <span style={{ alignSelf: "flex-start", flexGrow: 1 }}>
                    <span style={{ marginRight: 5 }}>&#128197;</span>
                    {format(new Date(receipt.entry_time), "h:mma MMM do, yyyy")}
                  </span>
                  <span style={{ alignSelf: "flex-end" }}>
                    <span style={{ marginRight: 5 }}>🏬</span>
                    {receipt.name}
                  </span>
                </div>
                {Array.isArray(receipt.items) &&
                  receipt.items.map((item) => {
                    return <Item item={item} key={item.sku} currencyConfig={currencyConfig} receipt={receipt} />
                  })}
                <div
                  style={{
                    maxWidth: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    marginLeft: "auto",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    <span>Subtotal</span>
                    <span style={{ float: "right" }}>{convertBackToActualPrice(receipt.subtotal, currencyConfig, true)}</span>
                  </span>
                  {receipt.totalDisc > 0 && (
                    <span>
                      <span>{env.getReceiptUIType() === 'AMEX' ? 'Amex Discount' : 'Discount'} ({receipt.promoCode})</span>
                      <span style={{ float: "right" }}>-{convertBackToActualPrice(receipt.totalDisc, currencyConfig, true)}</span>
                    </span>
                  )}
                  {receipt.discountDetails && Object.keys(receipt.discountDetails).length > 0 && (
                    <span>
                      {receipt && receipt.discountDetails && Object.keys(receipt.discountDetails).map((key, i) => (
                        <>
                          <span>{`Discount ${key ? `(${key})` : ''}`}</span>
                          <span style={{ float: "right" }}>-{convertBackToActualPrice(receipt.discountDetails[key], currencyConfig, true)}</span>
                          <br />
                        </>
                      ))}
                    </span>
                  )}
                  <span>
                    <span>Taxes</span>
                    <span style={{ float: "right" }}>{convertBackToActualPrice(receipt.totalTaxes, currencyConfig, true)}</span>
                  </span>
                  <span>
                    { receipt.totalGratuity !== null &&
                      <>
                        <span>Gratuity</span>
                        <span style={{ float: "right" }}>{convertBackToActualPrice(receipt.totalGratuity, currencyConfig, true)}</span>
                      </>
                    }
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    <span>{receipt.receiptFormat && receipt.receiptFormat === EURO ? 'Net Total' : 'Total'}</span>{" "}
                    <span style={{ float: "right" }}>{convertBackToActualPrice(receipt.totalPaid, currencyConfig, true)}</span>
                  </span>
                  {Object.keys(paymentMethods).map((key, i) => (
                    <span>
                      {paymentMethods[i].payment_type === null ? '' :
                        <>
                          <span>{(paymentMethods[i].payment_type === "card") ? (receipt.brand === null ? "Card" : receipt.brand) + " *" + receipt.last4 : ((loyalty_details && loyalty_details.memberId) ? paymentMethods[i].name + getLoyaltyId(loyalty_details.memberId) : paymentMethods[i].name)}</span>{" "}
                          <span style={{ float: "right" }}>{convertBackToActualPrice(paymentMethods[i].amount, currencyConfig, true)}</span>
                        </>
                      }
                    </span>
                  ))}
                </div>
                {Array.isArray(receipt.refund_items) && receipt.refund_items.length > 0 && (
                  <>
                    <p>
                      <span style={{ marginRight: 5 }}>&#x1F4B8;</span>Refunds
                    </p>
                    {receipt.refund_items.map((item) => {
                      // item name isn't in refund array, find item details in items array
                      let itemWithDetails
                      receipt.items.forEach((itemDetailed) => {
                        if (item.sku === itemDetailed.sku) {
                          itemWithDetails = { ...itemDetailed }
                          itemWithDetails.quantity = item.quantity
                          itemWithDetails.amount = item.amount
                        }
                      })

                      return <Item item={itemWithDetails} key={itemWithDetails.sku} isRefund={true} currencyConfig={currencyConfig} receipt={receipt} />
                    })}
                  </>
                )}
                <br />
                {(refundMethods.length > 0) && (
                  <span style={{ fontWeight: "bold" }}>
                    <span style={{ marginLeft: 5 }}>Refunded To</span>{" "}
                  </span>
                )}
                <br />
                {Object.keys(refundMethods).map((key, i) => (
                  <div>
                    <span style={{ marginLeft: 5 }}>{(refundMethods[i].refund_type === "card") ? (receipt.brand === null ? "Card" : receipt.brand) + " *" + receipt.last4 : ((loyalty_details && loyalty_details.memberId) ? refundMethods[i].name + getLoyaltyId(loyalty_details.memberId) : refundMethods[i].name)}</span>{" "}
                    <span style={{ float: "right" }}>{convertBackToActualPrice(refundMethods[i].amount, currencyConfig, true)}</span>
                  </div>
                ))}
                {receipt.name === "Paycom 1" &&
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                    <span
                      style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                    >
                      All sales include 8.625% Local Sales Tax and 13.5% Mixed Beverage Tax.
                    </span>
                  </div>
                }
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                  <span
                    style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                  >
                    <span style={{ marginRight: 8 }}>&#128722;</span>
                    Order# {receipt.cart_id}
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                  <span
                    style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                  >
                    <span style={{ marginRight: 8 }}>&#128205;</span>
                    {receipt.name}, {receipt.location}
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                  <span
                    style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                  >
                    <span style={{ marginRight: 10 }}>&#9201;</span>
                    Your visit duration: {calcDurationTime(receipt)}
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                  <span
                    style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                  >
                    <span style={{ marginRight: 9 }}>&#9757;</span>
                    <a href={transactionEmail}>Problem or questions with this receipt?</a>
                  </span>
                </div>
                {(Array.isArray(receiptText) && receiptText.length > 0 || receiptData) && (
                  <>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                      <span
                        style={{ alignSelf: "flex-start", flexGrow: 1, fontWeight: 200, fontSize: 14 }}
                      >
                        <span style={{ marginRight: 8 }}>&#129534;</span>
                        Authorization Receipt Text (An auth was placed on your card when you entered the store.  Details below. Your card is charged the amount shown above in the receipt):
                      </span>
                    </div>
                    {receiptText && (
                      <>
                        {receiptText.map((item) => (
                          <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                            <span
                              style={{ fontWeight: 200, fontSize: 14, tabSize: 4 }}
                            >
                              &emsp;&emsp;&emsp;{item}
                            </span>
                          </div>
                        ))}
                      </>)}
                    {receiptData && (
                      <>
                        {Object.entries(receiptData).map(([key, value]) => (
                          <div style={{ marginLeft: 20, marginTop: 10 }}>
                            <span style={{ fontWeight: 200, fontSize: 14 }}>
                              {key}: {value}
                            </span>
                          </div>
                        ))}
                      </>)}
                  </>
                )
                }
              </div>
            )
          })}
        </>
      
    </div >
  )
}

export default ReceiptsList
