const CONVERSION_TYPE = "conversion"
const CONVERSION_OPERATION_MULTIPLY = "multiply"
const CONVERSION_OPERATION_DIVIDE = "divide"

export const convertBackToActualPrice = (amount, storeCurrency, showCurrencySymbol) => {
    let price = parseFloat(amount)
    if (price && storeCurrency && storeCurrency.config) {
      let storeCurrencyConfig = JSON.parse(storeCurrency.config)
      if (storeCurrencyConfig.type === CONVERSION_TYPE) {
        let conversionAmount = parseFloat(storeCurrencyConfig.amount)
        if (storeCurrencyConfig.operation === CONVERSION_OPERATION_MULTIPLY) {
          price = price / conversionAmount
        } else if (storeCurrencyConfig.operation === CONVERSION_OPERATION_DIVIDE) {
          price = price * conversionAmount
        }
      }
    }
    if (showCurrencySymbol && storeCurrency && storeCurrency.symbol) {
      let currencySymbol = storeCurrency.symbol
      if (price >= 0) {
        price = currencySymbol + (price === 0 ? price : price.toFixed(2))
      } else {
        price = "- " + currencySymbol + (price * -1).toFixed(2)
      }
      return price
    } else {
      return price === 0 ? price : price.toFixed(2)
    }
  }

export const EURO = "eu"