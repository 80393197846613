import React from 'react';
import './App.css';
import Receipts from './Receipts'
import * as env from './env'

let logo = env.getReceiptUILogo()

function App() {

  return (
    <div className={env.getReceiptUIType()==="brightline" ? "App brightline-font" : "App" }>
      <header className="App-header">
      {env.getReceiptUIStoreName &&
      <div style={{ textAlign: 'center' }}>
        <h3 style={{ margin: 0}}>
        {env.getReceiptUIStoreName()}</h3>
      </div>}
      {logo && <a href="https://www.getzippin.com" target="_blank" rel="noreferrer"><img src={logo} className="App-logo" alt="logo" /></a>}
      </header>
      <div style={{ margin: 'auto' }}>
        {env.getReceiptUIType() === "lulu" && <p>After your Credit Card is charged, you can find your <strong>RECEIPT</strong> here</p>}
        <div style={{ textAlign: 'center' }}>
          <h3 className={env.getReceiptUIType()==="brightline" ? "header-font" : ""}>
            <span style={{ marginRight: 5 }}>&#x1F9FE;</span>
            Receipts
          </h3>
        </div>
        <Receipts />
      </div>
    </div>
  );
}

export default App;
